<template>
  <nav-list :title="$t('system.navigation.chats')">
    <nav-list-item>
      <router-link :to="{ name: 'chatDialogs' }">{{
        $t('system.navigation.inboxChat')
      }}</router-link>
      <div class="e-badge e-offcanvas__nav-badge chatmessage-badge">
        <el-tooltip
          :content="$t('system.message.total')"
          class="item"
          effect="dark"
          placement="top"
        >
          <div class="e-badge__item">{{ userInfo.chatEventCount }}</div>
        </el-tooltip>
      </div>
    </nav-list-item>
    <nav-list-item v-if="chatIsOpen || hasActiveChat">
      <router-link :to="{ name: 'liveChat' }">
        {{ $t('system.navigation.currentChat') }}
        <el-tooltip
          v-if="newChatMessage"
          :content="$t('chat.newMessage')"
          effect="dark"
          placement="top"
        >
          <span class="e-badge e-badge--simple" />
        </el-tooltip>
      </router-link>
    </nav-list-item>
  </nav-list>
</template>

<script>
import { mapGetters } from 'vuex'
import NavList from '../NavList/NavList.vue'
import NavListItem from '../NavList/NavListItem.vue'

export default {
  name: 'ChatNav',
  computed: {
    ...mapGetters(['userInfo', 'chatIsOpen', 'newChatMessage', 'hasActiveChat'])
  },
  components: { NavList, NavListItem }
}
</script>
