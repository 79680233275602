<template>
  <div class="e-supporters">
    <div class="e-supporters-text">
      {{ $t('system.supportedBy') }}
      <div class="e-supporters-text__links">
        <a href="https://www.nina-info.de" target="_blank" rel="noopener">www.nina-info.de</a> <br>
        <a href="https://www.beauftragte-missbrauch.de" target="_blank" rel="noopener">www.beauftragte-missbrauch.de</a>
      </div>
    </div>
    <ul class="e-supporters-list">
      <li class="e-supporters-list__item e-supporters-list__item--UBSKM">
        <IconUBSKM class="e-supporters-list__logo UBSKM" />
      </li>
      <li v-if="showLogo" class="e-supporters-list__item e-supporters-list__item--logo">
        <LogoSupport class="e-supporters-list__logo" />
      </li>
    </ul>
  </div>
</template>

<script>
import IconUBSKM from 'assets/icons_orig/logo-ubskm.svg'
import LogoSupport from 'atoms/Logo/LogoSupport'

export default {
  name: 'SupportersBar',
  components: {
    IconUBSKM,
    LogoSupport
  },
  props: {
    showLogo: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss">
@import '_assets/supporters';
</style>
