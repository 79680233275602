<template>
  <ul>
    <li v-if="!userInfo && showHomeLink">
      <router-link :to="{ name: 'index' }" exact>
        {{ $t('system.navigation.back') }}
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'support' }">
        {{ $t('system.navigation.support') }}
      </router-link>
    </li>
    <li>
      <router-link
        :to="{ name: 'content', params: { contentName: 'privacy' } }"
      >
        {{ $t('system.navigation.privacy') }}
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'content', params: { contentName: 'gtou' } }">
        {{ $t('system.navigation.gtou') }}
      </router-link>
    </li>
    <li>
      <router-link
        :to="{ name: 'content', params: { contentName: 'contact' } }"
      >
        {{ $t('system.navigation.contact') }}
      </router-link>
    </li>
    <li>
      <router-link
        :to="{ name: 'content', params: { contentName: 'imprint' } }"
      >
        {{ $t('system.navigation.imprint') }}
      </router-link>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'NavigationMeta',
  props: {
    showHomeLink: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  }
}
</script>
