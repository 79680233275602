<template>
  <li class="e-nav-list-item">
    <slot></slot>
  </li>
</template>
<script>
export default {
  name: 'NavListItem'
}
</script>
<style lang="scss">
@import '_assets/NavList';
</style>
