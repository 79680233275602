<template>
  <nav class="e-offcanvas__nav">
    <h4 v-if="title" class="e-offcanvas__nav-title">{{ title }}</h4>
    <ul>
      <slot></slot>
    </ul>
    <slot name="append"></slot>
  </nav>
</template>
<script>
export default {
  name: 'NavList',
  props: {
    title: {
      type: String
    }
  }
}
</script>
<style lang="scss">
@import '_assets/NavList';
</style>
