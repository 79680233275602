<template>
  <footer class="e-footer">
    <span v-show="!hideLogos" class="e-brand">
    </span>
    <supporters
      class="e-footer__supporters"
      :show-logo="true"
    />
  </footer>
</template>

<script>
import Supporters from 'atoms/Supporters/Supporters'
import { mapGetters } from 'vuex'

export default {
  name: 'TheFooter',
  components: {
    Supporters
  },
  props: {
    hideLogos: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['counselingCenterId'])
  }
}
</script>

<style lang="scss" scoped>
@import '_assets/footer';
</style>
