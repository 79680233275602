<template>
  <NavList
    class="e-offcanvas__nav--account"
    :title="$t('system.navigation.myProfile')"
  >
    <NavListItem>
      <router-link :to="{ name: 'account' }">
        {{ $t('system.navigation.account') }}
      </router-link>
    </NavListItem>
    <NavListItem>
      <router-link :to="{ name: 'changePassword' }">
        {{ $t('system.navigation.changePassword') }}
      </router-link>
    </NavListItem>
    <template v-slot:append>
      <ul>
        <li>
          <router-link
            v-if="userInfo.feedbackToolEnabled"
            :to="{ name: 'feedback' }"
          >
            {{ $t('system.navigation.feedback') }}
          </router-link>
          <span v-else>
            {{ $t('system.navigation.feedback') }}
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <button type="button" @click="__user__confirmLogout">
            {{ $t('system.navigation.logout') }}
          </button>
        </li>
      </ul>
    </template>
  </NavList>
</template>

<script>
import { mapGetters } from 'vuex'
import { userActions } from 'mixins'
import NavList from '../NavList/NavList.vue'
import NavListItem from '../NavList/NavListItem.vue'

export default {
  name: 'NavigationAccount',
  mixins: [userActions],
  computed: mapGetters(['userInfo']),
  components: { NavList, NavListItem }
}
</script>
