var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"e-header"},[(_vm.counselingLogoLink && !_vm.loadLogoFailed)?_c('router-link',{staticClass:"e-header__brand e-brand e-brand--custom",attrs:{"to":{name: _vm.userInfo && _vm.userInfo.id ? 'loginLanding' : 'index'}}},[_c('el-tooltip',{attrs:{"content":_vm.counselingCenterName}},[_c('img',{staticClass:"e-brand__logo e-brand__logo--img",class:("e-brand__logo--" + _vm.aspectRatioFormat),attrs:{"src":_vm.counselingLogoLink},on:{"error":function($event){_vm.loadLogoFailed = true}}})])],1):_c('router-link',{staticClass:"e-header__brand e-brand",attrs:{"to":{name: _vm.userInfo && _vm.userInfo.id ? 'loginLanding' : 'index'}}},[_c('Logo',{staticClass:"e-brand__logo e-brand__logo--svg"}),_c(_vm.logo,{tag:"component",staticClass:"e-brand-secondary"})],1),_c('nav',{staticClass:"e-header__nav"},[_c('MetaNav')],1),_c('div',{staticClass:"e-header__buttons"},[(_vm.userInfo)?[(_vm.$route.name === 'liveChat' || _vm.$route.name === 'chatDialogs')?_c('router-link',{staticClass:"e-btn e-btn--primary e-header__button",attrs:{"to":{
          name: 'dialogs'
        }}},[_c('IconEnvelope'),_c('span',{staticClass:"e-mobile-hidden e-tablet-hidden"},[_vm._v(" "+_vm._s(_vm.$t('chat.emailSupport'))+" ")])],1):[(!_vm.chatIsOpen)?_c('button',{staticClass:"e-btn is-disabled e-header__button",attrs:{"disabled":!_vm.chatIsOpen,"aria-disabled":!_vm.chatIsOpen}},[_c('span',{staticClass:"e-mobile-hidden e-tablet-hidden"},[_vm._v(" "+_vm._s(_vm.$t('chat.navigation'))+" ")]),_c('chat-icon',{staticClass:"e-tablet-show",attrs:{"show-notification":false}})],1):_c('router-link',{class:[
            { 'needs-chat-interaction': _vm.newChatMessage },
            'e-btn',
            'e-btn--quaternary',
            'e-header__button'
          ],attrs:{"to":{
            name: 'liveChat'
          }}},[_c('chat-icon',{attrs:{"show-notification":false,"notification-message":_vm.$t('chat.newMessage')}}),_c('span',{staticClass:"e-mobile-hidden e-tablet-hidden"},[_vm._v(" "+_vm._s(_vm.$t('chat.navigation'))+" ")])],1)]]:_vm._e(),_c('el-button',{staticClass:"e-btn--alert e-header__button",attrs:{"name":"logoutbutton"},on:{"click":_vm.logoutAndCatchFire}},[_c('IconExclamation'),_c('span',{staticClass:"e-mobile-hidden e-tablet-hidden"},[_vm._v(" "+_vm._s(_vm.$t('system.panic'))+" ")])],1)],2),(!_vm.dialogOpen && !_vm.chatDialogOpen && !_vm.messageEditorOpen)?_c('button',{staticClass:"e-offcanvas__toggle-header",attrs:{"type":"button","name":"offcanvas_toggle"},on:{"click":_vm.toggleOffcanvas}},[_c('span'),_c('span'),_c('span')]):_vm._e(),(
      !!_vm.chatDialogOpen &&
        (_vm.$route.name === 'chatDialogs' || _vm.$route.name === 'chatDialog')
    )?_c('router-link',{staticClass:"e-header__toggle",attrs:{"to":{ name: 'chatDialogs' }}},[_c('IconChevronLeft')],1):_vm._e(),(
      !(!_vm.dialogOpen && !_vm.messageEditorOpen) &&
        (_vm.$route.name === 'dialogs' ||
          _vm.$route.name === 'dialog' ||
          _vm.$route.name === 'newDialog')
    )?_c('router-link',{staticClass:"e-header__toggle",attrs:{"to":{ name: 'dialogs' }}},[_c('IconChevronLeft')],1):_vm._e(),(
      !(!_vm.dialogOpen && !_vm.messageEditorOpen) && _vm.$route.name === 'sentDialog'
    )?_c('router-link',{staticClass:"e-header__toggle",attrs:{"to":{ name: 'sent' }}},[_c('IconChevronLeft')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }