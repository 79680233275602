<template>
  <div
    :class="{ 'e-offcanvas--loggedin': userInfo && userInfo.id }"
    class="e-offcanvas"
  >
    <button class="e-offcanvas__toggle" type="button" @click="toggleOffcanvas">
      <IconChevronLeft />
    </button>

    <div class="e-offcanvas__inner">
      <div v-if="userInfo && userInfo.id" class="e-offcanvas__user">
        {{ $t('system.hello') }}, <strong>{{ userInfo.username }}</strong>
      </div>

      <MessageNav v-if="userInfo && userInfo.id" />
      <ChatNav v-if="userInfo && userInfo.id" />
      <CounselingEventNav v-if="userInfo && userInfo.id" />
      <AccountNav v-if="userInfo && userInfo.id" />

      <nav class="e-offcanvas__nav e-offcanvas__nav--meta">
        <MetaNav show-home-link />
      </nav>

      <footer class="e-offcanvas__footer">
        <Supporters class="e-offcanvas__supporter" />
      </footer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AccountNav from 'atoms/Accountnav/Accountnav'
import MessageNav from 'atoms/Messagenav/Messagenav'
import MetaNav from 'atoms/Metanav/Metanav'
import Supporters from 'atoms/Supporters/Supporters'

// Icons
import IconChevronLeft from 'assets/icons/chevron-left.svg'
import ChatNav from '../../atoms/ChatNav/ChatNav.vue'
import CounselingEventNav from '../../atoms/CounselingEventNav/CounselingEventNav.vue'

export default {
  name: 'TheOffcanvas',
  components: {
    IconChevronLeft,
    AccountNav,
    MessageNav,
    MetaNav,
    Supporters,
    ChatNav,
    CounselingEventNav
  },
  computed: {
    ...mapGetters(['offcanvasOpen', 'userInfo'])
  },
  methods: {
    ...mapActions(['setOffcanvas']),
    toggleOffcanvas() {
      this.setOffcanvas(!this.offcanvasOpen)
    }
  }
}
</script>

<style lang="scss">
@import '_assets/offcanvas';
</style>
