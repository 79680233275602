<template>
  <nav-list :title="$t('system.navigation.messages')">
    <nav-list-item>
      <router-link :to="{ name: 'dialogs' }">{{
        $t('system.navigation.inbox')
      }}</router-link>
      <div class="e-badge e-offcanvas__nav-badge">
        <el-tooltip
          :content="$t('system.message.unread')"
          class="item"
          effect="dark"
          placement="top"
        >
          <div class="e-badge__item e-badge__item--highlight">
            {{ unreadCount }}
          </div>
        </el-tooltip>

        <el-tooltip
          :content="$t('system.message.total')"
          class="item"
          effect="dark"
          placement="top"
        >
          <div class="e-badge__item">{{ threadCount }}</div>
        </el-tooltip>
      </div>
    </nav-list-item>
    <nav-list-item>
      <router-link :to="{ name: 'sent' }">{{
        $t('system.navigation.sent')
      }}</router-link>
      <div class="e-badge e-offcanvas__nav-badge">
        <el-tooltip
          :content="$t('system.message.total')"
          class="item"
          effect="dark"
          placement="top"
        >
          <div class="e-badge__item">{{ messagesSentCount }}</div>
        </el-tooltip>
      </div>
    </nav-list-item>
  </nav-list>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import NavList from '../NavList/NavList.vue'
import NavListItem from '../NavList/NavListItem.vue'

export default {
  name: 'NavigationMessages',
  computed: {
    ...mapGetters(['dialogs', 'sentMessages', 'userInfo']),
    threadCount() {
      return this.userInfo.threadCount
    },
    messagesSentCount() {
      return this.userInfo.messagesSentCount
    },
    unreadCount() {
      return this.userInfo.unreadCount
    }
  },
  mounted() {
    if (!this.dialogs) {
      this.getDialogs()
    }
    if (!this.sentMessages) {
      this.getSentMessages()
    }
  },
  methods: mapActions(['getSentMessages', 'getDialogs']),
  components: { NavList, NavListItem }
}
</script>
