<template>
  <Logo />
</template>

<script>
import Logo from 'assets/icons/logo-1000grad.svg'

export default {
  name: 'Logo1000grad',
  components: {
    Logo
  }
}
</script>
<style lang="scss" scoped>
.e-brand-secondary {
  min-width: 90px;
  height: 50px;
  margin-left: 10px;

  @include break(s) {
    min-width: 110px;
  }

  @include break(m) {
    min-width: 70px;
    margin-left: 5px;
  }

  @include break(ml) {
    min-width: 80px;
    margin-left: 10px;
  }

  // other pages
  .e-overall--loggedin & {
    min-width: 80px;
  }

  fill: map-get(map-get($kittn-themes, 1000grad), color);
}
</style>
