<template>
  <nav-list :title="$t('system.navigation.counselingEvents')">
    <nav-list-item>
      <router-link :to="{ name: 'counselingEvents' }">{{
        $t('system.navigation.currentCounselingEvents')
      }}</router-link>
    </nav-list-item>
  </nav-list>
</template>

<script>
import NavList from '../NavList/NavList.vue'
import NavListItem from '../NavList/NavListItem.vue'

export default {
  name: 'CounselingEventNav',
  components: { NavList, NavListItem }
}
</script>
